.card {
  position: relative;
  width: 30%;
  height: 210px;
  perspective: 800px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  border: 1px solid;
  border-image: linear-gradient(to top, #ffffff, #9d20b1, #ffffff) 1;
  background-clip: content-box, border-box;
  cursor: default;
  margin: 2rem auto;
  text-align: center;
  text-transform: uppercase;
}

.card.flipped {
  transform: rotateY(180deg);
}

.side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
}
.side h3 {
  margin: 1rem auto auto auto;
  color: white;
}
.side h2 {
  margin: 1rem auto auto auto;
  color: #2aa7a7;
}
.side p {
  font-size: 15px;
  text-align: left;
  margin-left: 2rem;
  padding-right: 20px;
  letter-spacing: 0.5px;
}
.back {
  transform: rotateY(180deg);
  background-color: #2aa7a7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 18px;
}
.back p {
  margin-top: -0.6rem;
}

@media screen and (min-width: 320px) and (max-width: 1197px) {
  .card {
    margin: 1rem auto;
  }
  .side p {
    font-size: 12.7px;
    padding-right: 10px;
    letter-spacing: 0.5px;
  }
  .side h2 {
    font-size: 20px;
  }
}
@media screen and (min-width: 320px) and (max-width: 770px) {
  .card {
    margin: 1rem auto;
    width: 85%;
  }
}
