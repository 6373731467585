.textHeader {
  position: relative;
  font-size: 40px;
  letter-spacing: 2px;
  text-align: center;
  top: 13rem;
  text-shadow: 4px 3px 7px rgba(255, 255, 255, 0.5);
  filter: blur(0.5px);
}
.textHeaderChild {
  text-align: center;
  text-wrap: inherit;
  padding: 35px;
  position: relative;
  font-size: 27px;
  letter-spacing: 2px;
  top: 10rem;
  text-shadow: 4px 3px 7px rgba(255, 255, 255, 0.5);
  filter: blur(0.5px);
  text-transform: uppercase;
}
.Img,
.imgBody {
  width: 65%;
  margin: 5rem auto;
}
.text {
  margin: 1rem auto 1rem auto;
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.textColor {
  color: #2aa7a7;
  font-weight: bold;
}
.rowVid {
  margin: 10rem auto auto auto;
}
.textVideo {
  font-size: 19px;
  text-transform: uppercase;
}
.vidC {
  position: relative;
  top: -110px;
  width: 120%;
}
@media screen and (min-width: 1246px) and (max-width: 1286px) {
  .textHeader {
    font-size: 30px;
    top: 13rem;
  }
  .textHeaderChild {
    top: 10rem;
    font-size: 21px;
  }
}
@media screen and (min-width: 320px) and (max-width: 1246px) {
  .textHeader {
    top: 11rem;
    font-size: 30px;
  }
  .textHeaderChild {
    top: 8rem;
    font-size: 21px;
    margin: auto;
  }
  .Img {
    width: 70%;
    margin: auto auto 5rem auto;
  }
  .imgBody {
    width: 95%;
    margin: 5rem auto;
  }
  .textVideo {
    font-size: 15px;

    text-align: center;
  }
  .vidC {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 1115px) {
  .textHeader {
    font-size: 25px;
  }
  .textHeaderChild {
    font-size: 15px;
  }
  .Img {
    width: 95%;
    margin: -14rem auto auto auto;
  }
  .text {
    font-size: 15px;
  }
  .textVideo {
    font-size: 15px;
    margin: auto auto 5rem auto;
    text-align: center;
  }
  .vidC {
    width: 100%;
    display: flex;

    margin: 5rem auto auto auto;
  }
}
@media screen and (min-width: 324px) and (max-width: 380px) {
  .textFS {
    margin: 1rem auto;
    font-size: 6px;
  }
}
@media screen and (min-width: 379px) and (max-width: 430px) {
  .textFS {
    margin: 1rem auto;
    font-size: 7px;
  }
}
@media screen and (min-width: 474px) and (max-width: 510px) {
  .textFS {
    margin: 1rem auto;
    font-size: 9px;
  }
}
@media screen and (min-width: 513px) and (max-width: 528px) {
  .textFS {
    font-size: 9px;
  }
}
