@media screen and (max-width: 1080px) {
  .our-services {
    text-align: center;
    background-color: #060b2d;
    font-size: 10px !important;
    position: relative;
    margin-top: -50rem;
    height: 850px;
  }
}
