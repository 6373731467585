.organizational {
  margin-top: 0;
  padding: 50px 0 50px 0;
  background-image: url("/public/assets/img/HomePage/Group 2.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
  
}
.content-organizational {
  text-align: center;
  position: relative;
  top: -80px;
  font-size: 25px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 800px;
}
.content-organizational .text-organizational {
  position: relative;
  font-size: 34px;
}
.content-organizational .description {
  position: relative;
  font-size: 20px;
  /* margin-top: 25rem; */
}

@media screen and (min-width: 320px) and (max-width: 1280px) {
  .organizational {
    text-align: center;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #060b2d;
    font-size: 10px;
    margin: auto;
    position: relative;
    top: -350px;
    z-index: -9999;
  }
  .content-organizational {
    top: 220px;
    font-size: 20px;
    margin: auto;
  }

  .content-organizational .text-join {
    position: relative;
    top: -110px;
    font-size: 15px;
    margin: auto;
  }
  .content-organizational .text-organizational {
    position: relative;
    top: -105px;
    font-size: 18px;
    margin: auto;
    text-wrap: inherit;
    max-width: min-content;
  }
  .content-organizational .description {
    font-size: 15px;
    position: relative;
    top: 100px;
    margin: auto;
    max-width: max-content;
    text-wrap: inherit;
    width: auto;
  }
}

@media screen and (min-width: 511px) and (max-width: 1119px) {
  .content-organizational {
    top: 170px;
    font-size: 20px;
    margin: auto;
    width: auto;
  }
  .content-organizational .text-organizational {
    font-size: 19px;
    max-width: min-content;
  }
  .content-organizational .description {
    width: 50%;
  }
}
@media screen and (min-width: 1120px) and (max-width: 1280px) {
  .content-organizational {
    top: 140px;
    font-size: 20px;
    margin: auto;
    width: auto;
  }
  .content-organizational .text-organizational {
    font-size: 19px;
    max-width: min-content;
  }
  .content-organizational .description {
    top: 150px;
    width: 50%;
  }
}
