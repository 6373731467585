.TitleHeader {
  top: 24rem;
  text-transform: uppercase;
}
.textHeader {
  position: relative;
  z-index: 99;
}
select {
  position: relative;
  z-index: 100;
  background-color: inherit;
  border: 3px white solid;
  border-radius: 30px;
  outline: none;
  color: white;
  height: 3.3rem;
  width: 280px;
  margin: 1rem 2rem;
  text-align: center;
  padding: 10px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: 3px center;
  background-size: 40px;
}
.input {
  position: relative;
  z-index: 100;
  background-color: inherit;
  border: 3px white solid;
  border-radius: 30px;
  outline: none;
  color: white;
  text-transform: uppercase;
  height: 3.3rem;
  width: 280px;
  margin: 1rem 2rem;
  text-align: left;
  padding-left: 47px;
  background-repeat: no-repeat;
  background-position: 3px center;
  background-size: 40px;
}
.inputImg_1 {
  background-image: url("/public/assets/img/YouPage/Input_PR1.png");
}
.inputImg_2 {
  background-image: url("/public/assets/img/YouPage/Input_PR2.png");
}
.inputImg_3 {
  background-image: url("/public/assets/img/YouPage/Input_PR3.png");
}
.inputImg_4 {
  background-image: url("/public/assets/img/YouPage/Input_PR4.png");
}
.inputImg_title {
  background-image: url("/public/assets/img/YouPage/Input_title.png");
}
.input::placeholder {
  color: white;
  text-align: left;
  text-transform: uppercase;
  padding-left: 50px;
}
.error {
  color: rgb(251, 12, 12);
  text-align: left;
  text-transform: uppercase;
  padding-left: 50px;
  margin-bottom:0px;
}
.inputT {
  width: 97%;
}

.inputT::placeholder {
  text-align: left;
  text-transform: uppercase;
  padding-left: 5px;
}
.inputT[placeholder="Shift acquired"]::placeholder {
  padding-left: 40px;
}
.inputT[id="domain_id"] {
  padding-left: 50px;
}
select option {
  text-align: left;
  text-transform: uppercase;
  padding-left: 25px;
  background: rgb(157 32 177);
  color: white;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
select option:selected {
  text-align: right;
  padding-left: 40px;
  
}
.textArea {
  position: relative;
  z-index: 100;
  background-color: inherit;
  border: 3px white solid;
  border-radius: 0 30px 30px 30px;
  outline: none;
  color: white;
  width: 97%;
  margin: 1rem 2rem;
  padding: 10px;
}
::file-selector-button {
  display: none;
}
input[type="file"] {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
.textArea::placeholder {
  color: white;
  text-transform: uppercase;
  padding-left: 10px;
}
.select::placeholder {
  color: white;
  text-transform: uppercase;
  padding-left: 10px;
}
/*--------------------Clients----------------------*/
.inputImgCL_1 {
  background-image: url("/public/assets/img/YouPage/Input_CL1.png");
}
.inputImgCL_2 {
  background-image: url("/public/assets/img/YouPage/Input_CL2.png");
}
.inputImgCL_3 {
  background-image: url("/public/assets/img/YouPage/Input_CL3.png");
  background-size: 80px;
}
.inputImgCL_3::placeholder {
  text-align: left;
  padding-left: 85px;
}
.inputT[name="LocClient"]::placeholder {
  padding-left: 40px;
}
/*--------------------Clients----------------------*/
@media screen and (min-width: 1200px) and (max-width: 1287px) {
  .TitleHeader {
    top: 20rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px) {
  .TitleHeader {
    top: 21rem;
  }
  .textHeader {
    margin-top: -15rem;
  }
  .input {
    margin: 1rem auto;
  }
  .inputT {
    width: 97%;
  }
  
  .colInput {
    margin: auto;
    width: 90%;
  }
  .textArea {
    margin: 1rem auto;
  }
}
@media screen and (min-width: 320px) and (max-width: 455px) {
  .textHeader {
    margin-top: -15rem;
  }

  .inputT {
    width: 280px;
  }
  .colInput {
    margin: auto;
    width: auto;
  }
  .textArea {
    width: 280px;
  }
}
