.PromiseYou {
  text-transform: uppercase;
}
.textContentHeader {
  font-size: 28px;
  margin-top: -10rem;
}

.textContent {
  font-size: 23.5px;
  margin: 0 2rem;
}
.img {
  margin: auto 1rem;
}
.img:hover {
  filter: saturate(300%);
  animation: pulse 0.9s infinite alternate;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.cardL {
  height: 50vh;
  background-color: #060b2d;;
  align-items: center;
}
.cardR {
  height: 50vh;
  background-color: #060b2d;
  align-items: center;
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .cardL {
    font-size: 17px;
    height: auto;
    padding: 30px 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .cardR {
    font-size: 17px;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .cardL p,
  .cardR p {
    font-size: 17px;
    text-align: center;
    margin-top: 1rem;
  }

  .textContentHeader {
    position: relative;
    margin: auto;
    width: 90%;
  }
}
