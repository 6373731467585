.grow {
  padding: 175px 0 100px 0;
  background-image: url("/public/assets/img/HomePage/Grow.png");
  background-position: center;
  background-repeat: no-repeat;
  height: 1000px;
}
.Service-grow{
  
  padding: 175px 0 100px 0; 
  margin-top: 20rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 700px;
  border: none; /* إزالة الحدود */
  border-radius: 10px; /* تطبيق حواف مستديرة بنسبة 10٪ */
}
.growImage {
  margin-top:5rem;
  padding: 0px 0 100px 0;
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url("/public/assets/img/HomePage/Group 3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  animation: opac 3s ease-in;
  border: none; /* إزالة الحدود */
  border-radius: 10px; /* تطبيق حواف مستديرة بنسبة 10٪ */
}
@keyframes opac {
  0% {
    opacity: 1; /* في الأعلى، تكون الضبابية صفر */
  }
  100% {
    opacity: 0.5; /* في الأسفل، تكون الضبابية كاملة (1) */
  }
}
.content-grow img {
  width: 100%;
}
.content-grow {
  position: relative;
  top: 150px;
  width: 100%;
  font-size: 46px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
.content-grow .text-one {
  position: relative;
  top: -90px;
  width: 100%;
  font-size: 46px;
}
.content-grow .text-tow {
  position: relative;
  top: -110px;
  width: 100%;
  font-size: 46px;
}
.content-grow .text-three {
  position: relative;
  top: -70px;
  width: 100%;
  font-size: 20px;
}
.content-grow-section-tow {
  position: relative;
  top: 35rem;
}
.content-grow-section-tow .video-section-tow {
  height: 420px;
  width: 450px;
  position: relative;
  top: -80px;
  z-index: -5;
}
.content-grow-section-tow .text-one {
  color: #9d20b1;
  font-size: 50px;
}
.content-grow-section-tow .text-tow {
  font-size: 25px;
}
.content-grow-section-tow .text-three {
  font-size: 18px;
  color: #ffffff;
}
.content-grow-section-tow button {
  color: white;
  font-size: 17px;
  background-color: #2aa7a7;
  border: none;
  height: 37px;
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .grow {
    background-size: contain;
    width: 100%;

    position: relative;
    top: -950px;
    margin: 10rem auto;
  }
  .content-grow {
    text-align: center;
    height: 45px;
    width: 100%;
    margin: auto;
  }
  .content-grow .text-one {
    top: -40px;
    height: 45px;
    width: auto;
    font-size: 18px;
  }
  .content-grow .text-tow {
    position: relative;
    top: -70px;
    margin: auto;
    font-size: 19px;
  }
  .content-grow .text-three {
    position: relative;
    top: -58px;
    font-size: 15px;
    margin: auto;
  }
  .content-grow-section-tow {
    position: relative;
    top: 33rem;
    margin: 5rem auto;
    text-align: center;
  }
  .content-grow-section-tow-text {
    margin: auto;
  }
  .content-grow-section-tow .video-section-tow {
    height: 291px;
    width: 272px;
    margin: auto;
    position: relative;
    top: 0px;
  }
  .content-grow-section-tow .text-one {
    padding-top: 2rem;
    text-align: center;
    color: #9d20b1;
    font-size: 25px;
    margin: auto;
  }
  .content-grow-section-tow .text-tow {
    font-size: 20px;
    padding: 0.5rem 2rem;
    margin: auto;
  }
  .content-grow-section-tow .text-three {
    padding: 1rem;
    font-size: 15px;
    color: #ffffff;

    margin: auto;
  }
  .growImage {
    margin-top: -27rem;
    height: 400px;
  }
  .Service-grow {
    margin-top: -50rem;
    height: 1000px;
    margin-bottom: 60rem;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .grow {
    position: relative;
    top: -850px;
  }
  .growImage {
    margin-top: -27rem;
  }
  .Service-grow {
    margin-top: -50rem;
    height: 1000px;
    margin-bottom: 60rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1286px) {
  .content-grow-section-tow .video-section-tow {
    width: 350px;
    height: auto;
  }
  
}
