.bgVid {
  position: absolute;
  bottom: 0;
  left: 0px;
  top: -10px;
  width: 100%;
  z-index: -10;
}

@media (min-aspect-ratio: 16/9) {
  .bgVid {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .bgVid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.rowHeader {
  height: 950px;
}
