.card- {
  margin-bottom: 3rem;
  text-transform: uppercase;
}
.card-:hover {
  border: 1px solid;
  border-image: linear-gradient(to top, #ffffff, #9d20b1, #ffffff) 1;
  background-clip: content-box, border-box;
  cursor: default;
}
p,
h3 {
  color: white;
}
.card- h2 {
  color: #2aa7a7;
}

@media screen and (min-width: 320px) and (max-width: 1080px) {
  .card- {
    width: 96%;
    margin: 2%;
    font-size: 15px;
    font-family: "Josefin Sans", sans-serif;
  }
}
