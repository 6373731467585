.card {
  background-color: #060b2d;
  box-shadow: 0 0 15px 5px #2aa7a7;
  border-radius: 12px 12px 10px 10px;
  border: none;
  text-transform: uppercase;
  width: 25rem;
  height: 45rem;
}
.cardHeader {
  background: linear-gradient(90deg, #9d20b1, #2aa7a7);
  text-align: center;
  border: 2px solid #707070;
  padding: 10px;
}
.cardHeader:first-child {
  border-radius: 10px 10px 0 0;
}
.cardBody {
  color: white;
  text-align: center;
  padding: 10px;
  border: 3px solid #2aa7a7;
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.textTitle {
  margin-top: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 23px;
}
.titleContaint {
  width: max-content;
  margin: 0.2rem auto 1rem auto;
  font-weight: normal;
  font-size: 17px;
  letter-spacing: 0;
}

.cardText {
  font-size: 15px;
}
@media screen and (min-width: 320px) and (max-width: 1246px) {
  .card {
    max-width: 18rem;
    margin: auto;
  }
  .cardImg {
    width: 90%;
  }
  .textTitle {
    font-size: 18px;
  }
  .titleContaint {
    font-size: 15px;
  }
  .cardText {
    font-size: 13px;
  }
}
