.scroll-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.scroll-text {
  margin-top: 10px;
  letter-spacing: 2px;
  color: #ffffff;
  font-size: 10px;
}

.mouse {
  border: 3px solid white;
  position: relative;
  width: 32px;
  height: 60px;
  border-radius: 100px;
  background-size: 100% 200%;
}

.mouse:before, .mouse:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.mouse:before {
  width: 32px;
  height: 60px;
  border-radius: 100px;
}

.mouse:after {
  background-color: #ffffff;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  animation: trackBallSlide 5s linear infinite;
}

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  15%, 19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  45%, 49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
  }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
  }
  75%, 79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
  }
}


@media screen and (max-width: 600px) {
  .scroll-container {
    margin-top: 5px;
  }
  
  .scroll-text {
    margin-top: 10px;
    font-size: 8px;
    letter-spacing: 6px;
    text-indent: 7px;
  }

  .mouse {
    border: 2px solid white;
    width: 15px;
    height: 20px;
  }

  .mouse:before {
    width: 7px;
    height: 10px;
  }
  
  .mouse:after {
    width: 3px;
    height: 3px;
    animation: trackBallSlide 2s linear infinite;
  }


  @keyframes trackBallSlide {
    0% {
      opacity: 1;
      transform: scale(0.1) translateY(-20px);
    }
    88%, 100% {
      opacity: 1;
      transform: scale(1) translateY(0px);
    }
  }
}