a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar {
  padding: 10px 10px;
  background-color: rgb(18, 18, 18, 0.4);
}
nav.navbar.scrolled {
  padding: 0px 0px;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  margin: 0 10px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 600;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 20px;
  margin: 0 20px;
  font-size: 15px;
  opacity: 0.75;
  text-transform: uppercase;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.navbar-text button {
  height: 44px;
  width: 100px;
  font-weight: 700;
  color: #fff;
  border: none;
  text-align: center;
  /*letter-spacing: 2px;*/
  font-size: 15px;
  margin-left: 5px;
  background-color: #9d20b1;
}
.navbar-text .btn-ln {
  width: 50px;
  font-weight: 700;
  color: #fff;
  font-size: 15px;
  background-color: #2aa7a7;
}
.navbar-text button span {
  z-index: 1;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.dropdown-menu {
  margin-top: 10px !important;
  margin-left: 20px !important;
  background-color: rgb(20, 18, 18, 0.7);
  /*  box-shadow: 0 10px 15px -3px rgba(122, 122, 122, 0.08),
  0 4px 6px -2px rgba(71, 63, 79, 0.16);*/
  width: max-content;

  text-transform: uppercase;

  padding-right: 20px;
}
.dropdown-menu .dropdown-menu {
  left: 103%;
  top: -40%;

  text-transform: lowercase;
}

.nav-link {
  font-weight: 600;
  color: #fff !important;
  letter-spacing: 0.8px;
  margin: 0px 15px;
  font-size: 15px;
  opacity: 0.75;
  text-align: left;
}
/* .nav-item .dropdown:hover,.dropdown .nav-link:hover{
  background: #000000;
}*/

.dropdown-toggle::after {
  margin: 0 4px;
}
.dropdown-menu .dropdown-toggle::after {
  border: solid #eeeeee;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  vertical-align: 0;
  right: -100%;
  transform: rotate(-45deg);
}

.logo {
  height: auto;
  width: 300px;
}

@media screen and (min-width: 320px) and (max-width: 1080px) {
  .logo {
    height: auto;
    width: 150px;
  }
  nav.navbar {
    padding: 10px 10px;
    background-color: #121212;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    margin: auto;
    margin-top: 2.5%;
  }
  span.navbar-text {
    display: flex;
    flex-direction: row;
  }
  .navbar-text button {
    margin: auto;
    margin-left: 2%;
    margin-bottom: 2%;
    width: 96%;
  }
  .navbar-text .btn-ln {
    margin: auto;
    margin-left: 2%;
    width: 96%;
    margin-bottom: 4%;
  }
  .dropdown-menu {
    border: 1px solid #fff;
    margin: 1rem auto;

    width: auto;
  }

  .nav-link .dropdown-menu {
    text-align: left;
  }
}
@media screen and (min-width: 320px) and (max-width: 991.5px) {
  .dropdown-menu .dropdown-toggle::after {
    position: inherit;
    transform: rotate(45deg);
    margin: 4px;
  }
  .nav-link {
    text-wrap: inherit;
    text-align: center;
  }
}
@media screen and (min-width: 320px) and (max-width: 1285px) {
  .social-icon {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-self: stretch;
  }
  .social-icon button {
    margin-bottom: 0px !important;
    margin-left: 8px;
  }
}
