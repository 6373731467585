.sub-services-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-services-img-header {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sub-services-content-header {
  position: absolute;
  margin: auto;
  width: 50%;
  height: 30%;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.sub-services-img-icon {
  width: 197px;
  height: 190px;
}

.sub-services-text {
  text-transform: uppercase;
  text-align: center;
  white-space: wrap;
  width: 70%;
}

@media screen and (max-width: 600px) {
  .sub-services-img-icon {
    width: 100px;
    height: 90px;
  }

  .sub-services-text {
    font-size: 20px;
  }
  
}

