.inputImg_1 {
  background-image: url("/public/assets/img/BlogPage/date.png");
}
.inputImg_2 {
  background-image: url("/public/assets/img/BlogPage/domain.png");
}
.inputImg_3 {
  background-image: url("/public/assets/img/BlogPage/authır.png");
}
.inputImg_4 {
  background-image: url("/public/assets/img/BlogPage/nationality.png");
}
.inputImg_4::placeholder {
  padding-left: 40px;
}
