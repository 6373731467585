.approach {
  padding: 175px 0 100px 0;
  background-image: url("/public/assets/img/HomePage/Group 4.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1000px;
}
.content-approach {
  text-align: center;
  font-size: 28px;
  position: relative;
}
.content-approach .text--one {
  font-size: 28px;
  color: white;
}
.content-approach .text-tow {
  color: #2aa7a7;
  font-size: 63px;
  text-shadow: 50px 2px 50px #2aa7a7;
}
.content-approach .text-three {
  font-size: 20px;
}
.content-approach .text--four {
  font-size: 14px;
}
.content-approach button {
  color: white;
  font-size: 17px;
  font-weight: bold;
  background-color: #2aa7a7;
  border: none;
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .approach {
    margin-top: 23rem;
    margin-bottom: 5rem;
    background-image: url("/public/assets/img/HomePage/Group 4.png");
    background-position: top center;
    background-size: contain;
  }
  .content-approach {
    top: -27rem;
    margin: auto;
  }
  .content-approach .text--one {
    font-size: 18px;
    margin: 1rem auto;
  }
  .content-approach .text-tow {
    font-size: 25px;
    margin: 1rem auto;
  }
  .content-approach .text-three {
    font-size: 18px;

    margin: 1rem auto;
  }
  .content-approach .text--four {
    font-size: 15px;
    margin: 1rem auto;
  }
  .content-approach button {
    position: relative;
    top: -20px;
    font-size: 15px;
    margin: auto;
  }
}
