.title-row {
  text-align: center;
  margin-top: 20px;
}

.services-section {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

.h-line {
  opacity: 1;
  width: 100px;
  color: #9d20b1;
  margin: auto;
  border: 2px solid #9d20b1;
}

.services-icons {
  height: 90%;
  width: 90%;
}
.services-icons:hover {
  
    transform: scale(1.2);
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .services-label {
    font-size: 10px;
  }
}
