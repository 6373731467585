.px-7 {
  padding: 0 10rem;
}

@media screen and (max-width: 960px) {
  .px-7 {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 550px) {
  .service-icon {
    height: 5rem !important;
    width: 5rem !important;
    object-fit: contain;
  }
  .service-name {
    font-size: 15px;
  }
 
  .service-description {
    font-size: 10px;
  }

  .services-details-numbers {
    height: 200px !important;
  }

  .service-dot {
    height: 25px !important;
    width: 25px !important;
  }
  
  .service-number {
    font-size: 90px !important;
  }

  .services-numbers {
    top: -35px !important;
  }

}

.service-section:hover .show-on-hover {
  display: block !important;
}

.service-section:hover .hide-on-hover {
  display: none !important;
}

.service-icon {
  height: 100px;
  width: 100px;
}
.service-icon:hover {
  filter: 
    invert(0.5)
    sepia(1)
    hue-rotate(200deg)
    saturate(4)
    brightness(1);
    transform: scale(1.3);
}
.service-dot {
  height: 45px;
  width: 45px;
  background-color: #2aa7a7;
  border-radius: 50%;
  display: inline-block;
}

.service-number {
  font-size: 150px;
  color: white;
  opacity: 13%;
  font-weight: bold;
}
strong {
  color: #2aa7a7;
    font-weight: bold;
}
.services-details-numbers {
  position: relative;
  width: 100%;
  height: 200px;
}

.services-details {
  position: absolute;
  top: 0px;
}

.services-numbers {
  position: absolute;
  top: -80px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
