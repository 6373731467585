.border-btm{
  border-bottom: 1px solid gray;
}

.px-7 {
  padding: 0 10rem;
}

@media screen and (max-width: 960px) {
  .px-7 {
    padding: 0 2rem;
  }
}

.section-bg {
  background-color: #050926;
}

