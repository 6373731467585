.CardImage {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 1080px) {
  .CardImage {
    position: relative;
    top: -260px;
    left: -5px;
    margin-right: 0.5%;
    margin-left: 1%;
    width: 48%;
    margin-bottom: 1rem;
  }
}
