.rowHeader {
  margin: 200px auto;
}
.products {
  margin: 300px auto;
  height: 300px;
}

.Img {
  margin-bottom: 2rem;
  width: 70%;
}
.text {
  margin-top: 2rem;
  font-size: 22px;
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.colorD {
 
}
.colorL {
  background-color: #060b2d;
}

.footerText {
  font-size: 20px;
}
.rowFooter {
  margin: auto;
}
.classHeader {
  text-transform: uppercase;
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .rowHeader {
    margin: -5rem auto 13rem auto;
  }

  .Img {
    margin: auto;
    width: 95%;
  }

  .text {
    font-size: 20px;
    margin: 2rem auto auto auto;
  }
  .footerText {
    font-size: 15px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .products {
    margin: auto auto 200px auto;
    height: 300px;
  }
}
@media screen and (min-width: 320px) and (max-width: 760px) {
  .products {
    margin: auto;
    height: 300px;
  }
}
