.rowHeader {
  margin: 100px auto;
}
.textContent {
  font-size: 21.5px;
  margin: 0;
  text-align: left;

  text-transform: uppercase;
}
.img {
  margin: auto -1rem;
}
.img:hover {
  filter: saturate(300%);
  animation: pulse 0.9s infinite alternate;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.imgApproach {
  width: 70%;
}
.textContentRight {
  font-size: 21.5px;
  margin: 0 4.3rem;
  text-align: right;
  text-transform: uppercase;
}
.classesTextHeader {
  text-transform: uppercase;
  text-align: center !important;
  font-size: 30px;
}
.vidC {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .classesTextHeader {
    font-size: 22px;
    text-align: center !important;
  }
  .rowHeader {
    margin: -9rem auto 5rem auto;
  }
  .imgApproach {
    margin: auto;
    width: 100%;
  }
  .textContent p,
  .textContent h3 {
    text-align: left;
    margin: 1rem 1rem;
  }
  .textContentRight p,
  .textContentRight h3 {
    text-align: right;
    margin: 1rem 1rem;
  }
}
