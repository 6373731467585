.card {
  position: relative;
  width: 316px;
  height: 380px;
  perspective: 800px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  border: 5px solid #ffffff;
}

.card.flipped {
  transform: rotateY(180deg);
}

.side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
}
.side p {
  font-size: 13.2px;
  text-align: left;
  margin-left: 2rem;
  padding-right: 20px;
  letter-spacing: 0.5px;
}
/*.side h4{

    position: relative;
    top: -80px;
}*/
.back {
  transform: rotateY(180deg);
  background-color: #2aa7a7;
  color: #ffffff;
  display: flex;
  justify-content: center;
  /* align-items: center;*/
  font-size: 18px;
}
.back h6,
h4 {
  margin-left: 2rem;
  text-align: left;
}
.back h4 {
  margin-top: 1rem;
}
.back h6 {
  margin-top: -0.6rem;
  margin-bottom: 1.5rem;
}
.back p {
  margin-top: -0.6rem;
}
.front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.socialIcon {
  margin-left: 2rem;
  font-size: 25px;
  margin-top: -0.6rem;
  text-align: left;
}
.socialIcon a {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #2aa7a7;

  display: inline-flex;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.socialIcon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.socialIcon a:hover::before {
  transform: scale(1);
}
.socialIcon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.socialIcon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

.socialIconContaint:first-child {
  margin-right: 0.3rem;
}
.socialIconContaint:last-child,
.socialIconContaint:nth-child(2) {
  margin: auto 0.3rem;
}
@media screen and (min-width: 320px) and (max-width: 1197px) {
  .card {
    margin: 1rem auto;
  }
  .side p {
    font-size: 12.7px;

    padding-right: 10px;
    letter-spacing: 0.5px;
  }
}
