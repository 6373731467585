.StartingSec {
  height: 800px;
  margin: 20px;
  text-transform: uppercase;
}
.textStarting {
  text-align: center;
  font-size: 40px;
  color: white;
}

.Img {
  width: 95%;
  margin: 5rem auto;
  transition: transform 0.7s ease-in-out; /* تأثير الانتقال */
}
.Img:hover {
  transform: scale(1.05); /* تكبير الصورة عند مرور المؤشر عليها */

  /*background-color: rgba(255, 255, 255, 0.04);*/
  /*animation: opac 3s infinite;*/
  border: none; /* إزالة الحدود */
  border-radius: 10px; /* تطبيق حواف مستديرة بنسبة 10٪ */
}
@keyframes opac {
  0% {
    opacity: 1; /* في الأعلى، تكون الضبابية صفر */
  }
  50% {
    opacity: 0.5; /* في الوسط، تكون الضبابية 0.5 (متوسطة) */
  }
  100% {
    opacity: 1; /* في الأسفل، تكون الضبابية كاملة (1) */
  }
}
.txFoterstr {
  margin: 5rem 0;
  text-align: center;
  font-size: 20px;
}
.textStartContaint {
  font-size: 20px;
  text-align: center;
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .Img {
    width: 100%;
  }
  .txFoterstr {
    margin: auto;
    font-size: 15px;
  }
}
