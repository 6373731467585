@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Josefin+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,700;1,100;1,500;1,600;1,700&display=swap");

@font-face {
  font-family: "Bahij TheSansArabic-SemiBold ";
  src: url("/public/assets/fonts/Bahij TheSansArabic-SemiBold.ttf")
    format("truetype");
}
body {
  font-family: "Outfit", sans-serif;
  background-color: #060b2d;
  --toastify-color-error: #981507;
}
