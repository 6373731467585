.Row {
  height: 100px;
  margin-bottom: -2.5rem;
  margin-top: 2rem;
}
.button {
  height: 44px;
  width: 247px;
  font-weight: 700;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  background-color: #9d20b1;
  text-transform: uppercase;
  position: relative;
  z-index: 500;
}

@media screen and (min-width: 320px) and (max-width: 1080px) {
  .Row {
    margin-bottom: 3rem;
  }
}
