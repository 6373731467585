.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-trigger {
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #32bae3, #bf2de8);
  color: #060b2d;
  padding: 5px 10px;
  border-radius: 10px 0 10px 0;
  border: 2px solid #707070;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  max-width: 10%;
  font-family: "Outfit", sans-serif;
  text-wrap: inherit;
  height: 10vh;
  text-align: center;
  align-content: center;
  font-weight: bold;
  font-size: 17px;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
