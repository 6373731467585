.Img {
  width: 45%;
  margin: 5rem auto;
}
.ImgTow {
  width: 90%;
  margin: 5rem auto;
}
/*.ImgTow:hover {
  filter: brightness(2) saturate(300%);
  animation: pulse 0.9s infinite alternate;
}*/
  
.ImgThree {
  margin: 5rem auto;
}
/*.ImgThree:hover {
  filter: brightness(1.5) saturate(200%);
  animation: pulse 0.9s infinite alternate;
}*/
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.vidC {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 1172px) {
  .textHeaderChild {
    top: 8rem;
    font-size: 16px;
    width: min-content;
    margin: auto;
  }
  .ImgThree {
    width: 100%;
    margin: 5rem auto;
  }
}
@media screen and (min-width: 320px) and (max-width: 1246px) {
  .Img {
    width: 95%;
    margin: -10rem auto 3rem auto;
  }
  .ImgTow {
    width: 100%;
    margin: 1rem auto;
  }
  .ImgThree {
    margin: 5rem auto;
  }
  .vidC {
    width: 100%;

    margin-top: -6rem;
  }
}
@media screen and (min-width: 436px) and (max-width: 514px) {
  .textFS {
    margin: 1rem auto;
    font-size: 9.8px;
  }
}
@media screen and (min-width: 374px) and (max-width: 425px) {
  .textFS {
    font-size: 9px;
  }
  .vidC {
    width: 100%;

    margin-top: -6rem;
  }
}
