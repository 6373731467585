.Img {
  width: 50%;
  margin: 5rem auto;
}
/*.Img:hover {
  filter: brightness(1.3) saturate(1.4);
  animation: pulse 0.9s infinite alternate;
}*/
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.text {
  text-align: start;
}
.textBody {
  display: inline-block;
  margin: auto;
  font-size: 14.2px;
  text-wrap: inherit;
}
.textHeaderChild {
  font-size: 35px;
}
.img {
  margin: auto 1rem auto auto;
}
.rowImgText {
  margin-top: 2rem;
  justify-content: center;
  text-transform: uppercase;
}
.textMeasuring {
  margin: 6rem auto -4.5rem auto;
}
.cardL {
  height: 50vh;
  align-items: center;
  text-transform: uppercase;
}
.imgCard {
  margin: auto 5rem;
}

.cardR {
  height: 50vh;
  align-items: center;
  text-align: right;
  margin: 0 4.3rem;
  text-transform: uppercase;
}
.textContent {
  font-size: 20px;
  margin: 0 1rem;
}
.titleCard {
  color: #2aa7a7;
  font-weight: bold;
  font-size: 40px;
}
.vidCard {
  width: 170%;
  display: flex;
}
@media screen and (min-width: 320px) and (max-width: 1246px) {
  .textHeader {
    margin: -15rem auto auto auto;
  }
  .textHeaderChild {
    font-size: 25px;
  }
  .Img {
    width: 95%;
    margin: 1rem auto 3rem auto;
  }
  .img {
    width: max-content;
    margin: auto;
  }
  .textBody {
    margin: auto;
    font-size: 10.1px;
  }
  .textMeasuring {
    margin: 4rem auto 2rem auto;
  }

  .textContent h3 {
    font-size: 25px;
  }
  .textContent p {
    font-size: 15px;
    margin: 1rem auto 3rem auto;
  }
  .cardL {
    margin: 1rem auto 3rem auto;
    text-align: left;
  }
  .cardR {
    margin: 4rem auto 3rem auto;
    text-align: right;
  }
  .cardR .textContent {
    margin-top: 2rem;
  }

  .imgCard {
    margin: 1rem 1rem;
  }
  .textFooter {
    margin-top: 5rem;
  }
  .vidCard {
    width: 300px;
    margin: 1rem -4rem;
    position: relative;
    z-index: -5;
  }
}
@media screen and (min-width: 320px) and (max-width: 769px) {
  .cardL,
  .cardR {
    height: auto;
  }
  .vidCard {
    width: 300px;
    margin: auto;
  }
}
@media screen and (min-width: 429px) and (max-width: 576px) {
  .rowImgText {
    margin: auto;
    justify-content: start;
  }
}

@media screen and (min-width: 320px) and (max-width: 429px) {
  @media screen and (min-width: 320px) and (max-width: 377px) {
    .textBody {
      font-size: 8.5px;
      /* width: max-content;
            text-wrap: warp;*/
    }
  }
  @media screen and (min-width: 377px) and (max-width: 430px) {
    .textBody {
      font-size: 9.5px;
    }
  }
  .rowImgText {
    margin: auto;
    justify-content: start;
  }
  .textBody {
    display: block;
    margin: auto;
  }
  .img {
    width: 33px;
    margin: 0.7rem auto;
  }
  .vidCard {
    width: -webkit-fill-available;
    margin: auto;
  }
}
