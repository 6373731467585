.bgVid {
  position: absolute;
  bottom: 0;
  left: 0px;
  top: -10px;
  width: 100%;
  z-index: -10;
}
@media (min-aspect-ratio: 16/9) {
  .bgVid {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .bgVid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.rowHeader {
  height: 950px;
}
.contentStory {
  position: relative;
  font-size: 40px;
  letter-spacing: 2px;
  text-align: center;
  top: 25rem;
  text-shadow: 4px 3px 7px rgba(255, 255, 255, 0.5);
  filter: blur(0.5px);
}
.headerImage {
  position: relative;
  top: 260px;
  background: url("/public/assets/img/PortfolioPage/Polygon 1.png");
  background-repeat: no-repeat;
  background-size: 78%;
  background-position: center top;
}
.img {
  width: 78%;
  position: relative;
  top: 60px;
}
/*.arrow {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
text-align: center;
    position: relative;
    font-size: 25px;
    letter-spacing: 2px;
    top: 14rem;
    text-shadow: 4px 3px 7px rgba(255, 255, 255, 0.5);
    filter: blur(0.5px);
}
.down {

    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}*/
@media screen and (min-width: 320px) and (max-width: 1286px) {
  .headerImage {
    position: relative;
    top: 220px;
    background-size: 78%;
    margin-bottom: 5%;
  }
  .img {
    width: 75%;
  }
}
@media screen and (min-width: 320px) and (max-width: 1200px) {
  .headerImage {
    position: relative;
    top: 210px;
    background-size: 78%;
    width: 50%;
    margin-bottom: 5%;
  }
  .img {
    width: 62%;
  }
}
@media screen and (min-width: 320px) and (max-width: 1115px) {
  .headerImage {
    position: relative;
    top: 230px;
    background-size: 350px;
    width: 100%;
  }
  .img {
    width: 62%;
  }
}
/*
@media screen and (min-width: 320px) and (max-width: 1080px) {

    .headerImage{
        position: relative;
        top: 190px;
        background-size: 90%;
    }
  !*  .contentStory {
        margin: auto;
        font-size: 30px;
        top: -6rem;
    }*!
}
@media screen and (min-width: 320px) and (max-width: 767px) {

    .headerImage{
        position: relative;
        top: 190px;
        background-size: 68%;
        width: 90%;
    }
    !*  .contentStory {
          margin: auto;
          font-size: 30px;
          top: -6rem;
      }*!
}
@media screen and (min-width: 320px) and (max-width: 576px) {

    .headerImage{
        position: relative;
        top: 190px;
        background-size: 320px;
        width: 90%;
    }
    !*  .contentStory {
          margin: auto;
          font-size: 30px;
          top: -6rem;
      }*!
}*/
