.headerRow {
  height: 400px;
  width: 100%;
  margin: 0px;
  margin-top: 5.7rem;
  background: url("/public/assets/img/ContactUsPage/header.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.textHeader {
  text-align: right;
  text-transform: uppercase;
}
.title {
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  font-weight: bold;
  position: relative;
}
.titleP {
  letter-spacing: 2px;
  font-size: 19px;
}
.title::before {
  content: "";
  position: absolute;
  width: 120px;
  bottom: -6%; /* لتحديد طول الخط تحت الكلمة */
  height: 4px;
  background-color: #9d20b1;
}
.titleCenter::before,
.titleCenter::after {
  bottom: -35%;
  margin-left: 8%;
}
.text {
  margin: 4rem auto 2rem auto;
}
.inputImg_1 {
  background-image: url("/public/assets/img/ContactUsPage/Input_1.png");
  width: 380px;
}
.inputImg_2 {
  background-image: url("/public/assets/img/ContactUsPage/Input_2.png");
  width: 380px;
}
.inputImg_3 {
  background-image: url("/public/assets/img/ContactUsPage/Input_3.png");
  width: 825px;
}
.inputImg_4 {
  background-image: url("/public/assets/img/ContactUsPage/Input_4.png");
  width: 825px;
}
.inputImg_3::placeholder,
.inputImg_4::placeholder {
  padding-left: 50px;
}
.textArea {
  width: 825px;
}
@media screen and (min-width: 320px) and (max-width: 1287px) {
  .colInput {
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .inputImg_1,
  .inputImg_2,
  .inputImg_3,
  .inputImg_4,
  .textArea {
    margin: 1rem auto;
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
  .colInput {
    margin: auto;
    width: 100%;
  }
  .inputImg_1,
  .inputImg_2,
  .inputImg_3,
  .inputImg_4,
  .textArea {
    margin: 1rem auto;
    width: 100%;
  }
  .contactUs {
    position: relative;
    top: 0;
  }
  .headerRow {
    height: 15rem;
    margin: 1.5rem auto;
    background-size: 600px;
  }
  .textHeader h2 {
    font-size: 20px;
    margin: auto auto 0.2rem 8rem;
    text-align: center;
  }
  .textHeader p {
    font-size: 15px;
    margin: auto auto auto 10rem;
  }
  .titleCenter {
    font-size: 20px;
    margin: auto;
    text-align: center;
  }
  .containerContact {
    margin-top: -6rem;
  }
  .title::before {
    width: 60px;
    bottom: -5px;
  }

  .titleCenter::before {
    width: 80px;
    bottom: -35%;
    margin-left: 30px;
  }
  .text {
    margin: 2.2rem auto auto auto;
  }
  @media screen and (min-width: 455px) and (max-width: 955px) {
    .headerRow {
      margin: 3.5rem auto;
      background-size: 800px;
    }
    .text {
      margin: 3.5rem auto auto auto;
    }
    .text p {
      margin: -3.5rem auto auto auto;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 900px) {
    .headerRow {
      margin: 3.3rem auto;
      background-size: 100%;
    }
    .text {
      margin: 3.5rem auto auto auto;
    }
    .text p {
      margin: -3.5rem auto auto auto;
    }
  }
  @media screen and (min-width: 900px) and (max-width: 992px) {
    .headerRow {
      margin: 4rem auto;
      background-size: 100%;
    }
    .text {
      margin: 3.5rem auto auto auto;
    }
    .text p {
      margin: -3.5rem auto auto auto;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .headerRow {
      margin: 8rem auto;
      background-size: 100%;
    }
    .text {
      margin: 3.5rem auto auto auto;
    }
    .text p {
      margin: -3.5rem auto auto auto;
    }
  }
}
