.contact-section {
  padding: 20px;
  border-radius: 8px;
  outline: 4px solid #9d20b1;
  color: white;
  margin: 0px;
  height: 220px;
}

.contact-img {
  object-fit: contain;
  width: 100%;
  border-radius: 50%;
  outline: 6px solid #9e20b140;
}

.contact-section:hover {
  outline: 6px solid #9d20b1;
  transition: .2s ease-in;
}

.contact-section:hover .contact-img{
  outline: 6px solid #9e20b170;
  transition: .2s ease-in;
}


@media screen and (max-width: 500px) {
  .contact-section {
    font-size: 15px !important;
    padding: 10px;
  }
  .contact-img {
    width: 70%;
  }

}

@media screen and (max-width: 1199px) {
  .contact-img {
    object-fit: contain;
    width: 85%;
  }
}
