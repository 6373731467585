.rowheader {
  margin: 200px auto 100px auto;
}
.textHeader {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-top: -2rem;
}
.Img {
  width: 55%;
  margin-bottom: -1rem;
}
.bgImg {
  background-image: url("/public/assets/img/UsPage/Why QUAIM.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 630px;
  object-fit: cover;
}

.rowFotter {
  margin: 7rem auto auto auto;
}

.imageContainer {
  display: flex;
  flex-direction: column; /* ترتيب العناصر بشكل رأسي */
  gap: 10px; /* تباعد بين الصور */
}

.imageItem {
  display: flex;
  flex-direction: row; /* ترتيب الصور والوصف جنبًا إلى جنب */
  align-items: center; /* محاذاة العناصر بشكل عمودي */
  justify-content: center;
}

.imageItem img {
  max-width: 180px; /* تحديد عرض الصورة اليمينية */
  height: auto; /* الارتفاع يتعين تلقائيًا بناءً على النسبة */
  position: relative;
  cursor: pointer;
}
.img1 img {
  transform: translate(118%, 52%);
}
.img2 img {
  transform: translate(-1%, -119%);
  transition: transform 0.3s ease; /* تحديد المدة وتموج الانتقال للتأثيرين */
}
.img3 img {
  /*max-width: 180px;*/
  transform: translate(-113%, -165%);
}
.img4 img {
  transform: translate(-139%, -131%);
}

.img5 img {
  transform: translate(-60%, -142%);
}
.img6 img {
  transform: translate(72%, -247%);
}
.img7 img {
  transform: translate(142%, -460%);
}

 /*img:hover {
  filter: blur(5px) brightness(50%) saturate(50%) invert(0%) sepia(6%)
    saturate(98%) hue-rotate(346deg) brightness(95%) contrast(76%);
  opacity: 0.1;
  transform: translate(-1%, -119%) scale(1.08); /* تضبيط التأثير عند التحويل 
   // تحديد المدة وتموج الانتقال عند الهوفر 
}*/

.text {
  position: absolute;
}
.text1 {
  color: white;
  position: absolute;
  transform: translate(-1%, -850%);
  text-transform: uppercase;
  z-index: 1;

  transition: opacity 0.3s ease, transform 0.3s ease; /* تحديد المدة وتموج الانتقال للنص وتأثير التأخير */
  transition-delay: 0.2s; /* تأخير الظهور بـ 0.2 ثانية */
}

@media screen and (min-width: 1081px) and (max-width: 1286px) {
  .firstTextHeader {
    margin-top: -13rem;
  }
  .bgImg {
    margin-top: -10rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 1080px) {
  .rowheader {
    margin: -5rem auto;
    padding: 15px;
  }
  .Img {
    width: 95%;
    margin: 5rem auto;
  }

  .textHeader {
    margin: auto;
  }
  .rowFotter {
    padding: 15px;
    margin: -3rem auto 3rem auto;
  }
  .imageItem img {
    /*      top: 56px;
        width: 145px; !* تحديد عرض الصورة اليمينية *!
        height: auto; !* الارتفاع يتعين تلقائيًا بناءً على النسبة *!
        position: relative;*/
  }
  .bgImg {
    margin: 5rem auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .imageItem img {
    width: 180px; /* تحديد عرض الصورة اليمينية */
    height: auto; /* الارتفاع يتعين تلقائيًا بناءً على النسبة */
    position: relative;
  }
}

@media screen and (min-width: 593px) and (max-width: 768px) {
  .imageItem img {
    width: 145px; /* تحديد عرض الصورة اليمينية */
    height: auto; /* الارتفاع يتعين تلقائيًا بناءً على النسبة */
    position: relative;
  }
  .bgImg {
    background-position: top;
  }
  .img7 img {
    transform: translate(143%, -466%);
  }
  .img6 img {
    transform: translate(72.5%, -250%);
  }
  .img5 img {
    transform: translate(-60%, -143.5%);
  }
  .img4 img {
    transform: translate(-139.5%, -133%);
  }
  .img3 img {
    transform: translate(-113.5%, -167%);
  }
  .text1 {
    font-size: 13px;
  }
}
@media screen and (min-width: 400px) and (max-width: 593px) {
  .rowFotter {
    margin-top: -17rem;
  }
  .imageItem img {
    width: 100px; /* تحديد عرض الصورة اليمينية */
    height: auto; /* الارتفاع يتعين تلقائيًا بناءً على النسبة */
    position: relative;
  }
  .bgImg {
    background-position: top;
    background-size: 350px;
  }
  .img7 img {
    transform: translate(140%, -490%);
  }
  .img6 img {
    transform: translate(72.5%, -274%);
  }
  .img5 img {
    transform: translate(-60%, -160%);
  }
  .img4 img {
    transform: translate(-139%, -147%);
  }
  .img3 img {
    transform: translate(-112.5%, -175%);
  }
  .img2 img {
    transform: translate(-2%, -122%);
  }
  .img1 img {
    transform: translate(115%, 52.5%);
  }
  .text1 {
    font-size: 9px;
  }
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .rowFotter {
    margin-top: -22rem;
  }
  .imageItem img {
    width: 78px; /* تحديد عرض الصورة اليمينية */
    height: auto; /* الارتفاع يتعين تلقائيًا بناءً على النسبة */
    position: relative;
  }
  .bgImg {
    background-position: top;
    background-size: 277px;
  }
  .img7 img {
    transform: translate(143%, -505%);
  }
  .img6 img {
    transform: translate(72.5%, -283%);
  }
  .img5 img {
    transform: translate(-60%, -169.7%);
  }
  .img4 img {
    transform: translate(-139.5%, -154%);
  }
  .img3 img {
    transform: translate(-113.5%, -180%);
  }
  .img2 img {
    transform: translate(-2%, -123.5%);
  }
  .img1 img {
    transform: translate(118%, 50.5%);
  }
  .text1 {
    font-size: 7.3px;
  }
}
.us-icon:hover {
  filter: 
    invert(0.5)
    sepia(1)
    hue-rotate(200deg)
    saturate(4)
    brightness(1);
    transform: scale(1.3);
}
