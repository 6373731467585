.header-drop-down {
  margin: 2rem 0;
  padding: 5px;
  color: white;
  cursor: pointer;
  width: 100%;
  background-color: #2aa7a7;
  border-radius: 4px;
}

@media screen and (max-width: 550px) {
  .header-drop-down h3{
    font-size: 13px;
  }
}

.services-list {
  list-style-type: none;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  clip-path: inset(0 0 100% 0);
  animation: revealEffect 0.5s ease-out forwards;
}

.service-item{
  font-size: 12px;
  text-transform: capitalize;
}

@keyframes revealEffect {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

li {
  color: white;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid white;
}

.right-arrow {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transform: rotate(180deg);
}
