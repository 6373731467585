a {
  text-decoration: none;
}
.footer {
  margin-top: 10rem;
  width: 100%;
  background-color: #050821;
  padding-bottom: 13px;
}
.content-footer img {
  margin-top: 3.2rem;
}
.content-footer ul {
  margin-top: 4.2rem;
  display: flex;
  list-style: none;
}
.content-footer li {
  opacity: 0.7;
  margin-right: 3rem;
  color: white;
  cursor: pointer;
}
.content-footer li:hover {
  opacity: 1;
}

.h-line-end {
  opacity: 1;
  color: grey;
  margin: auto;
  border: -2px solid grey;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 1080px) {
  .footer {
    width: 100%;
    margin: auto;
  }
  .content-footer .logo-footer {
    width: 70% !important;
    display: block;
    text-align: center;
    margin: 2rem auto;
    object-fit: contain;
  }
  .content-footer ul {
    display: block;
    margin: 1rem;
  }
  .content-footer ul li {
    margin: 1rem auto;
  }
}
