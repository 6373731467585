.clients {
  margin-top: 10rem;
}
.content-client {
  text-align: center;
}
.content-client .text-one {
  font-size: 30px;
  color: #2aa7a7;
}
.content-client .text-tow {
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.clients {
  text-align: center;
}
.content-clients {
  margin: 3rem auto;
  text-align: center;
}
.content-clients .text-one {
  font-size: 60px;
  color: #2aa7a7;
  margin: auto;
}

.content-clients .text-tow {
  font-size: 20px;
}
.content-clients button {
  margin: 1rem auto;
  font-size: 20px;
  background-color: #2aa7a7;
  color: white;
  border: none;
  letter-spacing: 1px;
}

@media screen and (min-width: 320px) and (max-width: 1080px) {
  .clients {
    margin-top: -14rem;
  }
  .clients img {
    width: 100%;
  }
  .content-client {
    position: relative;
    top: -280px;
    text-align: center;
    margin: 1rem auto;
  }
  .content-client .text-one {
    font-size: 22px;
  }
  .content-client .text-tow {
    font-size: 15px;
  }
  .content-clients {
    position: relative;
    top: -200px;
    margin: auto;
    text-align: center;
  }
  .content-clients-text {
    margin: 1rem auto;
  }
  .content-clients .text-one {
    font-size: 25px;
  }
  .content-clients .text-tow {
    font-size: 16px;
  }
  .content-clients button {
    font-size: 15px;
  }
  .content-clients img {
    width: 100%;
    margin: auto;
  }
}
